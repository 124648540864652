import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import styles from "./Intro.module.scss"
import { Container } from "react-bootstrap"
// import { GatsbyImage } from "gatsby-plugin-image"
// import { isSVG } from "../../../../utilities/mediaUtils"
import useIsMobile from "../../../../hooks/useIsMobile"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getRenderingOptions } from "../../renderEmbeddedEntries"

const Intro = props => {
  const { locale, defaultLocale } = props.pageContext
  // const media = _.get(props, "data.contentfulClient.introMedia")
  const body = _.get(props, "data.contentfulClient.introBody")
  const isMobile = useIsMobile()

  // const renderMedia = () => {
  //   if (!media) return null

  //   if (isSVG(media)) {
  //     return (
  //       <img
  //         src={media.file.url}
  //         alt={media.description || ""}
  //         className={styles.background}
  //       />
  //     )
  //   } else if (media.gatsbyImageData) {
  //     return (
  //       <GatsbyImage
  //         className={styles.background}
  //         image={media.gatsbyImageData}
  //         alt={media.description || ""}
  //       />
  //     )
  //   }
  // }

  const renderContent = () => {
    return (
      <Container>
        <div className={styles.bodyWrap}>
          <div className={styles.bodyCard}>
            {renderRichText(body, getRenderingOptions(locale, defaultLocale))}
          </div>
        </div>
      </Container>
    )
  }

  if (!body) return null
  return (
    <div
      className={styles.intro}
      style={{ paddingTop: isMobile ? "6rem" : "8rem" }}
    >
      {/* {renderMedia()} */}
      {renderContent()}
    </div>
  )
}
Intro.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}
export default Intro
