import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import styles from "./Testimonial.module.scss"
const Testimonial = props => {
  const { author, detail, thumb, testimonial } = props

  const renderThumb = () => {
    if (!thumb || !thumb.gatsbyImageData) return null
    return (
      <div className={styles.thumbWrap}>
        <GatsbyImage
          className={styles.thumb}
          image={thumb.gatsbyImageData}
          alt={thumb.description || ""}
        />
      </div>
    )
  }

  const renderAuthorInfo = () => {
    if (!author && !detail) return null
    const textAlign = thumb ? { textAlign: "left" } : { textAlign: "center" }

    return (
      <div className={styles.authorInfo}>
        {!!author && (
          <p className={styles.author} style={textAlign}>
            {author}
          </p>
        )}
        {!!detail && (
          <p className={styles.detail} style={textAlign}>
            {detail}
          </p>
        )}
      </div>
    )
  }

  return (
    <div className={styles.testimonial}>
      <div className={styles.bodyWrap}>
        <p>&quot; {testimonial} &quot;</p>
      </div>
      <div className={styles.authorDetails}>
        {renderThumb()}
        {renderAuthorInfo()}
      </div>
    </div>
  )
}
Testimonial.propTypes = {
  author: PropTypes.string,
  detail: PropTypes.string,
  thumb: PropTypes.object,
  testimonial: PropTypes.string,
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired
}
export default Testimonial
