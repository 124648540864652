import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import Link from "../../../Link/Link"
import { GatsbyImage } from "gatsby-plugin-image"
import { isSVG } from "../../../../utilities/mediaUtils"
import { translate, KEYS } from "../../translations"
import styles from "./CompanyProfile.module.scss"
const CompanyProfile = props => {
  const { locale, defaultLocale } = props.pageContext
  const companyLogo = _.get(props, "data.contentfulClient.companyLogo", null)
  const companyName = _.get(props, "data.contentfulClient.companyName", "")
  const industry = _.get(props, "data.contentfulClient.activityArea", [])
  const companySize = _.get(props, "data.contentfulClient.companySize", [])
  const location = _.get(props, "data.contentfulClient.location", "")
  const useCase = _.get(props, "data.contentfulClient.useCase", [])
  const ctaLink = _.get(props, "data.contentfulClient.ctaLink", null)

  const infoItems = {
    industry: "industry",
    companySize: "companySize",
    location: "location",
    useCase: "useCase"
  }
  const renderLogo = () => {
    if (!companyLogo) return null

    if (isSVG(companyLogo)) {
      return (
        <div className={styles.logoWrap}>
          <img
            src={companyLogo.file.url}
            alt={companyLogo.description || ""}
            className={styles.companyLogo}
          />
        </div>
      )
    } else if (companyLogo.gatsbyImageData) {
      return (
        <div className={styles.logoWrap}>
          <GatsbyImage
            className={styles.companyLogo}
            image={companyLogo.gatsbyImageData}
            alt={companyLogo.description || ""}
          />
        </div>
      )
    }
  }

  const renderList = list => {
    return (
      <p>
        {list.map(
          (item, index) => `${item}${index === list.length - 1 ? "" : ", "}`
        )}
      </p>
    )
  }
  const renderInfoItem = (itemName, list) => {
    switch (itemName) {
      case infoItems.industry:
        if (!Array.isArray(list) || !list.length) return null
        return (
          <li className={styles.icon}>
            <p>{translate(KEYS.COMPANY_PROFILE_INDUSTRY, locale)}</p>
            {renderList(industry)}
          </li>
        )
      case infoItems.companySize:
        if (!Array.isArray(list) || !list.length) return null
        return (
          <li className={styles.icon}>
            <p>{translate(KEYS.COMPANY_PROFILE_SIZE, locale)}</p>
            {renderList(companySize)}
          </li>
        )
      case infoItems.location:
        return (
          !!location && (
            <li className={styles.icon}>
              <p>{translate(KEYS.COMPANY_PROFILE_LOCATION, locale)}</p>
              <p>{location}</p>
            </li>
          )
        )
      case infoItems.useCase:
        if (!Array.isArray(list) || !list.length) return null
        return (
          <li className={styles.icon}>
            <p>{translate(KEYS.COMPANY_PROFILE_USE_CASE, locale)}</p>
            {renderList(useCase)}
          </li>
        )
      default:
        return null
    }
  }
  const companyInfo = () => {
    return (
      <>
        <ul className={styles.infoItems}>
          {renderInfoItem(infoItems.industry, industry)}
          {renderInfoItem(infoItems.companySize, companySize)}
          {renderInfoItem(infoItems.location, location)}
        </ul>
        <ul className={styles.infoItems}>
          {renderInfoItem(infoItems.useCase, useCase)}
        </ul>
      </>
    )
  }

  const renderCTA = () => {
    if (!ctaLink) return null

    return (
      <div className={styles.companyCta}>
        <Link link={ctaLink} locale={locale} defaultLocale={defaultLocale} />
      </div>
    )
  }
  return (
    <div className={styles.companyProfile}>
      {renderLogo()}
      <div className={styles.companyInfoCard}>
        {!!companyName && (
          <h2 className={styles.companyTitle}>{companyName}</h2>
        )}
        {companyInfo()}
        {renderCTA()}
      </div>
    </div>
  )
}
CompanyProfile.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}
export default CompanyProfile
